var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.readMore)?_c('div',{staticClass:"tracking-wider prose prose-strong:text-brandRed",class:[
    _vm.columnsMobile,
    _vm.columnsDesktop,
    _vm.fontSizeMobile,
    _vm.fontSizeDesktop,
    _vm.alignMobile,
    _vm.alignDesktop,
    _vm.paddingHorizontal,
    _vm.desktopPaddingHorizontal,
    _vm.mobileMarginBottom,
    _vm.desktopMarginBottom,
    _vm.mobileMarginTop,
    _vm.desktopMarginTop,
    _vm.maxWidthDesktop,
    _vm.maxWidth,
    _vm.color,
  ],domProps:{"innerHTML":_vm._s(_vm.content.html)}}):_c('div',[_c('div',{staticClass:"relative tracking-wider prose prose-strong:text-brandRed",class:[
      _vm.columnsMobile,
      _vm.columnsDesktop,
      _vm.fontSizeMobile,
      _vm.fontSizeDesktop,
      _vm.alignMobile,
      _vm.alignDesktop,
      _vm.paddingHorizontal,
      _vm.desktopPaddingHorizontal,
      _vm.mobileMarginBottom,
      _vm.desktopMarginBottom,
      _vm.mobileMarginTop,
      _vm.desktopMarginTop,
      _vm.maxWidthDesktop,
      _vm.maxWidth,
      _vm.color,
      {
        'max-h-16 overflow-hidden scrollbar-hide before:w-full before:h-full before:bg-gradient-to-t before:from-white before:via-transparent before:absolute':
          !_vm.showMore && _vm.needsReadMore,
      },
    ],domProps:{"innerHTML":_vm._s(_vm.content.html)}}),_vm._v(" "),(_vm.needsReadMore)?_c('button',{staticClass:"mt-4 text-sm tracking-wider underline underline-offset-4",on:{"click":() => (_vm.showMore = !_vm.showMore)}},[_vm._v("\n    "+_vm._s(_vm.showMore ? _vm.$t('Read_Less') : _vm.$t('Read_More'))+"\n  ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }